import { actions } from '../actions/ui'
import { getters } from '../getters/ui'
import { mutations } from '../mutations/ui'

export const ui = {
  namespaced: true,
  state: {
    isModalOpen: false as boolean,
    showLoader: false as boolean,
    miniCart:{
      open:false
    },
    popups: {
      'loyaltyInfoPopUp': {
        open: false,
        props: {}
      },
      'payOverTimePopup': {
        open: false,
        props: {}
      },
      'emailMePopup': {
        open: false,
        props: {}
      },
      'loyaltyLoginRegister' : {
        open: false,
        props: {}
      },
      'loyaltyOnlyInfoPopUp' : {
        open: false,
        props: {}
      },
      'rxItemToCartDisabledPopup' : {
        open: false,
        props: {}
      }
    },
    panels: {
      'balancePanel': {
        open: false
      }
    },
    toasts: {
      'loyaltyLoginToast' : {
        open: false,
      }
    }
  },
  actions,
  getters,
  mutations,
}
